<template>
<div>
  <el-row>
    <el-col span="12" style="text-align: left; margin-top: 10px">
      <label style="font-size: 14px; margin-left: 1px"><!--{{
          headTitle
        }}--></label>
    </el-col>
    <el-col span="12" style="text-align: right;margin-top: 5px">
      <el-button v-if="saveBtnShow"
        type="primary"
        size="mini"
        @click="save"
      >确定</el-button>
      <el-button size="mini" @click="cancel">取消</el-button>
    </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  name: "dialog-head-btn",
  props: {
    headTitle: {
      type: Object,
      default: ""
    },
    icon: {
      type: Object,
      default: ""
    },
    saveBtnShow:{
      type:Boolean,
      default:true
    }
  },
  methods:{
    save(){
      this.$emit('dialog-head-save-click')
    },
    cancel(){
      this.$emit('dialog-head-cancel-click')
    }
  }
}
</script>

<style scoped>

</style>
